.button {
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 0;
}
.button:before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 1px;
  border-radius: 8px;
  background: linear-gradient(90deg, #4dc9e6 0%, #210cae 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.button img {
  margin-left: 5px;
}

.content {
  display: flex;
  align-items: center;
  color: white;
}
.button:hover {
  background: linear-gradient(90deg, #4dc9e6 0%, #210cae 100%);
  border-radius: 8px;
  transition: background 0.3s ease-in-out;
}
