* {
  font-family: 'Graphik LCG';
  font-weight: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url(../src/landing/assets/fonts/GraphikLCG-Regular.ttf);
  font-weight: 400; /* normal */
}

@font-face {
  font-family: 'Graphik LCG';
  src: url(../src/landing/assets/fonts/GraphikLCG-Medium.ttf);
  font-weight: 500; /* medium */
}

@font-face {
  font-family: 'Graphik LCG';
  src: url(../src/landing/assets/fonts/GraphikLCG-Semibold.ttf);
  font-weight: 600; /* semibold */
}

@font-face {
  font-family: 'Graphik LCG';
  src: url(../src/landing/assets/fonts/GraphikLCG-Bold.ttf);
  font-weight: 700; /* bold */
}

@font-face {
  font-family: 'Graphik LCG';
  src: url(../src/landing/assets/fonts/GraphikLCG-Black.ttf);
  font-weight: 900; /* black */
}
